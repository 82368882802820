import Helmet from "react-helmet"
import React from "react"
import Trbm from "@tightrope/trbm"
import HomepageLayout from "src/layouts/homepage"
import CtaDisclosure from "@tightrope/ctadisclosure"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"

const homepageData = import("./homepage.json");

export default function HomePage() {


    let css;
      if(isFirefox()){
        css = `.trbm-module--trbm .trbm-module--bullets {
          padding-left: 35px;
          
        }

        }`;
      }
      else{
        css = `.trbm-module--trbm .trbm-module--bullets {
          padding-left: 0;
        }
  `;
      }
return(
    <HomepageLayout>
      <Helmet>
       <style type="text/css">{`
      body {
        background-image: url(/img/convertorfull-dark.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        background-position-x: center;
      }
      .footerlinks-module--br,
      .footerlinks-module--br a {
        color: #D9D9D9;
      }

      .trbm_cta {
          padding: 15px 100px;
          border: none;
          font-size: 35px;
          color: #fff;
          font-weight: bold;
          background: #43ba19;
          border-radius: 10px;
          cursor: pointer;
          transition: .2s ease;
      }
      #ctadisclosure-module--ctadisclaimer {
          width:610px;
          color: #333;
          margin:0 auto;
      }
      `}
      </style>
      <style type="text/css">
        {css}
            </style>

      <title>Free File Conversions - frompdftodoc.com</title></Helmet>
      <Trbm data={homepageData}><CtaDisclosure></CtaDisclosure></Trbm>
    </HomepageLayout>
  )
}
